import React, { useEffect, useState } from 'react'
import { Document } from '@contentful/rich-text-types'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { ContentfulRichText } from '@/atoms/ContentfulRichText'
import { Image } from '@/atoms/Image'
import { AngelLogo } from '@/atoms/Logos/AngelLogo'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsDiv, AsH1, AsH2, CaptionMD, HeadingSM, HeadingXL, HeadingXS } from '@/atoms/Text'
import { slugs } from '@/constants'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { HorizontalItemScroller } from '@/molecules/HorizontalItemScroller'
import { FaqAccordion } from '@/organisms/FaqAccordion'
import { MinimalistFooter } from '@/organisms/MinimalistFooter'
import { EmailCaptureSectionCopy } from '@/services/EmailCapturePageService'
import { Faq } from '@/services/FaqService'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useTranslate } from '@/utils/translate/translate-client'
import { AppPromoSection } from '@/views/LandingView/AppPromoSection'
import { EmailCapture } from '@/views/SignUpView/EmailCapture'
import { SignUpViewV2 } from '@/views/SignUpView/SignupViewV2'

const textShadowStyle = {
  textShadow: '0px 1px 0px rgba(0,0,0,0.3)',
}

export interface SignUpViewProps {
  heroImageCloudinaryPath: string
  watchAnywhereImageCloudinaryPath: string
  watchImages: {
    cloudinaryImagePath: string
    altText: string
  }[]
  subscriptionGroupId: string
  destination: string
  projectSlug: string
  projectName: string
  logoCloudinaryPath: string | null
  synopsisTitle?: string
  synopsis?: {
    json?: Document
  }
  buttonText?: string
  faqs: Faq[]
  heroSectionCopy: EmailCaptureSectionCopy
  midSectionCopy: EmailCaptureSectionCopy
  bottomSectionCopy: EmailCaptureSectionCopy
  sections: EmailCaptureSectionCopy[]
  footerText?: string
}

export const SignUpView: React.FC<SignUpViewProps> = (props) => {
  const {
    heroSectionCopy,
    midSectionCopy,
    bottomSectionCopy,
    heroImageCloudinaryPath,
    watchAnywhereImageCloudinaryPath,
    watchImages,
    subscriptionGroupId,
    destination,
    projectSlug,
    projectName,
    buttonText,
    logoCloudinaryPath,
    faqs,
    synopsis,
    synopsisTitle,
    sections,
    footerText,
  } = props

  const { t } = useTranslate('sign-up')
  const emailCaptureProps = {
    subscriptionGroupId,
    destination,
    projectSlug,
    projectName,
    buttonText,
  }
  const [success, setSuccess] = useState(false)
  const { asPath } = useRouter()

  useEffect(() => {
    const hash = (asPath as string).split('#')[1]
    setSuccess(hash?.includes('success'))
  }, [asPath])

  const defaultTagLine = t(
    'angelGuildMembersHelpFundOurShowsAndMore',
    'Angel Guild Members Help Fund Our Shows and More',
  )

  const imageSection = (
    <section className="bg-core-gray-950 lg:py-20">
      <HeadingXL
        as={AsH2}
        className="photon-title-lg lg:photon-heading-md 2xl:photon-heading-xl mb-16 text-center !normal-case text-gray-100"
        style={textShadowStyle}
      >
        {midSectionCopy?.header}
        {midSectionCopy?.subheader && (
          <HeadingSM as={AsDiv} className="lg:photon-heading-md mb-12 mt-2 !normal-case" style={textShadowStyle}>
            {midSectionCopy.subheader}
          </HeadingSM>
        )}
      </HeadingXL>

      {watchImages?.length > 0 && (
        <HorizontalItemScroller className="mb-16">
          {watchImages.map(({ cloudinaryImagePath, altText }) => {
            return (
              <div key={cloudinaryImagePath} className="relative pr-4">
                <div className="group-thumbnail w-fit overflow-hidden rounded-lg bg-black">
                  <Image className="rounded-lg" src={cloudinaryImagePath} alt={altText} height={180} width={320} />
                </div>
                <CaptionMD className="text-center text-gray-200">{altText}</CaptionMD>
              </div>
            )
          })}
        </HorizontalItemScroller>
      )}
      <EmailCapture
        position="section-2"
        formTagline={midSectionCopy?.tagLine ?? defaultTagLine}
        {...emailCaptureProps}
        shouldShowSuccess={success}
      />
    </section>
  )

  return sections.length === 0 ? (
    <ThemeContextProvider isDarkMode>
      <main className="relative h-screen min-h-[550px] w-screen">
        <ResponsiveBackgroundImage
          preBackgroundStyles="linear-gradient(180deg, rgba(21, 21, 21, 0.3) 50%, #151515 100%), linear-gradient(64.45deg, rgba(21, 21, 21, 0.5) 0%, rgba(21, 21, 21, 0.4) 66.17%), "
          className="h-[75vh] md:h-screen"
          src={heroImageCloudinaryPath}
          transforms="g_west"
        />
        <div className="absolute inset-x-6 top-6 md:inset-x-8 md:top-8">
          <div
            className={classNames(
              'flex items-center md:justify-between',
              logoCloudinaryPath ? 'justify-between' : 'justify-center',
            )}
          >
            <AngelLogo height={40} color="white" />
            {projectSlug !== slugs.angelStudios && logoCloudinaryPath && (
              <img
                alt=""
                className="ml-auto max-h-[40px] max-w-[50%] md:max-h-[60px] md:max-w-[33%] lg:max-w-[25%]"
                src={getCloudinaryImageUrl({ path: logoCloudinaryPath, height: 60, format: 'webp' })}
              />
            )}
          </div>
        </div>
        <div className="absolute top-1/2 w-full -translate-y-1/4 px-4 text-center">
          <header className="mx-auto mb-8 text-white sm:max-w-[475px] md:max-w-[640px] lg:max-w-[1000px]">
            <HeadingXL as={AsH1} className="lg:photon-display" weight="bold" style={textShadowStyle}>
              {heroSectionCopy?.header}
              <HeadingSM as={AsDiv} className="lg:photon-heading-md mb-12 mt-2 !normal-case" style={textShadowStyle}>
                {heroSectionCopy?.subheader}
              </HeadingSM>
            </HeadingXL>
          </header>
          <PaddedContainer fluid className="text-white">
            <EmailCapture
              position="section-2"
              formTagline={heroSectionCopy?.tagLine ?? defaultTagLine}
              {...emailCaptureProps}
              shouldShowSuccess={success}
            />
          </PaddedContainer>
        </div>
      </main>
      <PaddedContainer fluid className="relative mx-auto mt-8 bg-core-gray-950 text-white">
        {Boolean(watchImages?.length) && imageSection}
        <section className="bg-core-gray-950 text-center">
          <HeadingXL as={AsH2} className="lg:photon-display" weight="bold" style={textShadowStyle}>
            {bottomSectionCopy?.header}
            <HeadingSM as={AsDiv} className="lg:photon-heading-md mb-12 mt-2 !normal-case" style={textShadowStyle}>
              {bottomSectionCopy?.subheader}
            </HeadingSM>
          </HeadingXL>

          <AppPromoSection overrideAppImageUrl={watchAnywhereImageCloudinaryPath} />
          <div id="email-capture">
            <EmailCapture
              position="section-2"
              formTagline={bottomSectionCopy?.tagLine ?? defaultTagLine}
              {...emailCaptureProps}
              shouldShowSuccess={success}
            />
          </div>
        </section>

        {synopsis?.json && (
          <>
            <section role="complementary" className="relative w-full py-20 md:py-20">
              <PaddedContainer className="!max-w-[800px]">
                <HeadingXS weight="bold" className="mb-8 text-center ">
                  {synopsisTitle}
                </HeadingXS>
                <div className="flex flex-col items-center text-center">
                  <ContentfulRichText json={synopsis.json} />
                </div>
              </PaddedContainer>
            </section>
            {!watchImages?.length && imageSection}
          </>
        )}

        <section role="complementary" className="relative w-full py-20 md:py-40">
          <PaddedContainer>
            <FaqAccordion faqs={faqs} />
          </PaddedContainer>
        </section>
      </PaddedContainer>

      <MinimalistFooter footerText={footerText} />
    </ThemeContextProvider>
  ) : (
    <SignUpViewV2 {...props} />
  )
}
